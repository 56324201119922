import { registerService } from "./register.service.js";
import CookieService from "@/services/cookie.js";

export default {
    components: {},
    data: function() {
        return {
            firstName: "",
            lastName: "",
            email: "",
            pwd: "",
            onProcess: false
        };
    },
    methods: {
        goLogin() {
            this.$router.push({ name: "Login" });
        },
        handleRegister() {
            const thisVue = this;
            if (this.firstName == "") {
                $("#register-component .firstname-field .warning").css("display", "flex");
            }
            if (this.lastName == "") {
                $("#register-component .lastname-field .warning").css("display", "flex");
            }
            if (this.email == "") {
                $("#register-component .email-field .warning").css("display", "flex");
            }
            if (this.pwd == "") {
                $("#register-component .pwd-field .warning").css("display", "flex");
            }
            if (!this.firstName || !this.lastName || !this.email || !this.pwd) return;

            let ref = CookieService.get_cookie("ref");
            if (!thisVue.onProcess) {
                thisVue.onProcess = true;
                registerService.signUp(
                    JSON.stringify({
                        email: this.email,
                        password: this.pwd,
                        first_name: this.firstName,
                        last_name: this.lastName,
                        ref: ref
                    }),
                    function(data) {
                        if (data && data.data && data.data.message == "EMAIL EXISTS") {
                            thisVue.$swal({
                                title: "Email exists!",
                                type: "error",
                                showConfirmButton: false,
                                timer: 2500
                            });
                            thisVue.onProcess = false;
                            return;
                        }
                        if (data && data.data && data.data.message) {
                            thisVue.$swal({
                                title: data.data.message,
                                type: "error",
                                showConfirmButton: false,
                                timer: 2500
                            });
                            thisVue.onProcess = false;
                            return;
                        }
    
                        if (data.code === 200 && data.data && data.data.token) {
                            localStorage.setItem("Authorization", data.data.token);
                            localStorage.setItem("user", JSON.stringify(data.data.user));
                            thisVue.onProcess = false;
    
                            let id = localStorage.getItem("not-login-clone-app-id");
                            localStorage.removeItem("not-login-clone-app-id");
                            thisVue.$router.push({ name: "Dashboard", params: { id } });
                        } else {
                            thisVue.$swal({
                                title: "Register Error!",
                                type: "error",
                                showConfirmButton: false,
                                timer: 2500 
                            });
                            thisVue.onProcess = false;
                        }
                    }
                );
            }
            
        },
        nextInput(event) {
            console.log(event);
            if (event) {
                let selector= `#register-component .${event.path[1].className} + div input`;
                $(selector).trigger("focus");
            }
        }
    },
    mounted() {
        $("#register-component input").on("focus", function() {
            $("#register-component .warning").hide();
        });
    }
};
